<template>
  <div class="w-full flex space-x-3">
    <checkmark-tab
      v-for="option in tabOptions"
      :key="option.value"
      :data-cy="option.value"
      :active="selectedTab === option.value"
      class="cursor-pointer"
      @click="emit('tab:click', option.value)"
    >
      <template v-if="haveDataFor(option.value)" #prefix>
        <icon-base
          height="16"
          width="16"
          :icon="IconSuccessRoundBig"
          class="mr-2 text-success"
        />
      </template>
      {{ option.label }}
    </checkmark-tab>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import CheckmarkTab from "@/components/ui/LfCheckmarkTab.vue";
import IconSuccessRoundBig from "@/components/icons/IconSuccessRoundBig.vue";
import { useI18n } from "vue-i18n";
import { LexisNexisReturnTypes } from "@/enums/dataServices";
const props = defineProps({
  haveSearch: {
    type: Boolean,
    required: true
  },
  haveReport: {
    type: Boolean,
    required: true
  },
  selectedTab: {
    type: String,
    required: true
  }
});

const emit = defineEmits<{
  (eventName: "tab:click", key: LexisNexisReturnTypes): void;
}>();

const { t } = useI18n();

const tabOptions = ref([
  {
    label: t("COMMON.SEARCH"),
    value: LexisNexisReturnTypes.search
  },
  {
    label: t("COMMON.REPORT"),
    value: LexisNexisReturnTypes.report
  }
]);

const haveDataFor = (tab: LexisNexisReturnTypes) => {
  return (
    (tab === LexisNexisReturnTypes.search && props.haveSearch) ||
    (tab === LexisNexisReturnTypes.report && props.haveReport)
  );
};
</script>
